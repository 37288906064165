import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-your-homes',
  templateUrl: './your-homes.component.html',
  styleUrls: ['./your-homes.component.scss'],
  standalone: false,
})
export class YourHomesComponent {
  constructor(private router: Router) {}

  public addProperty(): void {
    this.router.navigate(['myzex/your-homes/details/new']);
  }
}
