import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[numbersOnly]',
  standalone: false,
})
export class NumbersOnlyInputDirective {
  constructor() {}

  @HostListener('input', ['$event']) onInputChange(event: any): any {
    if (!event.target.value) {
      return;
    }
    const initialValue = event.target.value;
    event.target.value = initialValue.replace(/[^0-9]*/g, '');
    if (initialValue !== event.target.value) {
      event.stopPropagation();
    }
  }
}
