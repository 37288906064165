import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-images-list',
  templateUrl: './images-list.component.html',
  styleUrls: ['./images-list.component.scss'],
  standalone: false,
})
export class ImagesListComponent implements OnInit, OnChanges {
  @Input() images: string[] = [];
  @Input() pending = false;
  @Input() previewMode = false;
  @Input() previewImages: Subject<File[]> = new Subject<File[]>();

  @Output() removeImage = new EventEmitter<number>();
  @Output() makeMainImage = new EventEmitter<number>();

  public previewFiles: any[] = [];

  constructor() {}

  public ngOnInit(): void {
    this.previewImages.subscribe((files: File[]) => {
      this.processPreviewFiles(files);
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.previewMode?.previousValue !== changes.previewMode?.currentValue) {
      this.previewFiles = [];
    }
  }

  private processPreviewFiles(files: File[]): void {
    for (let i = 0; i < files.length; i++) {
      const file: File = files[i];
      const reader = new FileReader();

      reader.onload = (e: any) => {
        this.previewFiles.push({
          name: file.name,
          type: file.type,
          preview: e.target.result,
        });
      };
      reader.readAsDataURL(file);
    }
  }

  public deleteImage(index: number): void {
    if (this.previewMode) {
      this.previewFiles.splice(index, 1);
    }

    this.removeImage.emit(index);
  }

  public onMakeMainImage(index: number): void {
    if (this.previewMode) {
      const firstElement = this.previewFiles.splice(index, 1);
      this.previewFiles.unshift(firstElement[0]);
    }

    this.makeMainImage.emit(index);
  }
}
