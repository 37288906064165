import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-image-uploader-v2',
  templateUrl: './image-uploader-v2.component.html',
  styleUrls: ['./image-uploader-v2.component.scss'],
  standalone: false,
})
export class ImageUploaderV2Component {
  @Output() filesSelected: EventEmitter<File[]> = new EventEmitter<File[]>();
  @Output() validationError: EventEmitter<string> = new EventEmitter<string>();

  maxFilesCount: number = 50;
  maxSizePerFile: number = 1024 * 1024 * 5;
  maxSizeAllFiles: number = 1024 * 1024 * 50;

  isDragging = false;

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    this.isDragging = true;
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    this.isDragging = false;

    let files: File[] = [];
    if (event.dataTransfer.items) {
      for (let i = 0; i < event.dataTransfer.items.length; i++) {
        if (event.dataTransfer.items[i].kind === 'file') {
          const file = event.dataTransfer.items[i].getAsFile();
          if (file) {
            files.push(file);
          }
        }
      }
    } else {
      files = this.extractFiles(event.dataTransfer.files);
    }

    this.validateAndEmit(files);
  }

  onDragLeave(event: any): void {
    event.preventDefault();
    if (!this.isDragging) {
      return;
    }

    this.isDragging = false;
    const e = new KeyboardEvent('keydown', { key: 'Escape' });
    document.dispatchEvent(e);
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    const files = this.extractFiles(input.files);
    this.validateAndEmit(files);
  }

  private extractFiles(fileList: FileList | null): File[] {
    const files: File[] = [];
    if (fileList) {
      for (let i = 0; i < fileList.length; i++) {
        files.push(fileList.item(i)!);
      }
    }
    return files;
  }

  private validateAndEmit(files: File[]): void {
    const validFiles: File[] = [];
    let totalSize = 0;

    for (const file of files) {
      const isImage = ['image/jpeg', 'image/jpg', 'image/png'].includes(file.type);
      const isSizeValid = file.size <= this.maxSizePerFile;

      if (isImage && isSizeValid) {
        validFiles.push(file);
        totalSize += file.size;
      } else {
        this.validationError.emit('Invalid files detected. Please check file types and sizes.');
      }
    }

    if (validFiles.length > 0 && totalSize <= this.maxSizeAllFiles && validFiles.length <= this.maxFilesCount) {
      this.filesSelected.emit(validFiles);
    }
  }
}
